import { useState, useCallback } from 'react';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';

import { ErrorMessage } from '../../../../../../../types';
import { MessageUUID } from '../../../../../../messages/messagesTypes';
import { FetchFinProjectMessagesQueryResponse } from '../../../../../../messages/queries/fetchFinProjectMessages.query';

import {
  CREATE_MESSAGE_IN_PROJECT_QUERY,
  CreateMessageInProjectQueryResponse
} from '../../../../../../messages/queries/createMessageInProject.query';

import {
  useCreateMessageInProject,
  CreateMessageInProjectInput
} from '../../../../../../messages/hooks/useCreateMessageInProject';

import {
  useCreateMockMessageWithFormData,
  CreateMockMessageWithFormDataProject,
  CreateMockMessageWithFormDataOptions
} from '../../../../../../messages/components/list/ItemMessagesList/hooks/useCreateMockMessageWithFormData';

import { ItemCreateMessageFormSubmit } from '../../../../../../messages/components/forms/ItemCreateMessageForm';
import { ItemMessagesListMessage } from '../../../../../../messages/components/list/ItemMessagesList';

import { parseRequestError } from '../../../../../../../utils/parseRequestError';

interface ProjectMessagesContentCreateMessageOptions {
  project?: CreateMockMessageWithFormDataProject;
  onAfterCreateMessage?: (
    newMessage: FetchFinProjectMessagesQueryResponse
  ) => void;
}

function useProjectMessagesContentCreateMessage({
  project,
  onAfterCreateMessage
}: ProjectMessagesContentCreateMessageOptions) {
  const [sendingMessages, setSendingMessages] = useState<
    ItemMessagesListMessage[]
  >([]);

  const handleRemoveSendingMessage = useCallback<
    (messageUuid: MessageUUID) => void
  >((messageUuid) => {
    setSendingMessages((prev) => {
      return remove(
        prev,
        (sendingMessage) => sendingMessage.uuid !== messageUuid
      );
    });
  }, []);

  const { createMessageInProject, createMessageInProjectLoading } =
    useCreateMessageInProject<CreateMessageInProjectQueryResponse>({
      projectNanoId: project?.nanoId,
      query: CREATE_MESSAGE_IN_PROJECT_QUERY
    });

  const handleMarkSendingMessageFailed = useCallback<
    (messageUuid: MessageUUID, errorMessage: ErrorMessage) => void
  >((messageUuid, errorMessage) => {
    setSendingMessages((prev) => {
      const failedMessageIndex = findIndex(
        prev,
        (sendingMessage) => sendingMessage.uuid === messageUuid
      );

      if (failedMessageIndex === -1) {
        return prev;
      } else {
        const newSendingMessages = [...prev];
        newSendingMessages[failedMessageIndex] = {
          ...prev[failedMessageIndex],
          errorMessage
        };
        return newSendingMessages;
      }
    });
  }, []);

  const createMockMessageFromFormData = useCreateMockMessageWithFormData();

  const handleCreateMessageInProject = useCallback<ItemCreateMessageFormSubmit>(
    async (data) => {
      const newSendingMessage = createMockMessageFromFormData({
        formData: data,
        project
      } as CreateMockMessageWithFormDataOptions);

      setSendingMessages((prev) => [...prev, newSendingMessage]);

      try {
        const result = await createMessageInProject({
          ...data,
          projectId: project?.id
        } as CreateMessageInProjectInput);

        handleRemoveSendingMessage(
          result?.createMessageInProject?.record?.uuid
        );

        onAfterCreateMessage?.(result?.createMessageInProject?.record);

        return result;
      } catch (err) {
        const errorMessage = parseRequestError(err);
        handleMarkSendingMessageFailed(data.uuid as MessageUUID, errorMessage);
        throw err;
      }
    },
    [
      createMockMessageFromFormData,
      project,
      createMessageInProject,
      handleRemoveSendingMessage,
      onAfterCreateMessage,
      handleMarkSendingMessageFailed
    ]
  );

  return {
    createMessageInProjectLoading,
    sendingMessages,
    handleCreateMessageInProject,
    handleRemoveSendingMessage
  };
}

export default useProjectMessagesContentCreateMessage;
